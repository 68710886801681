import logo from './logo.svg';
import './App.css';
import Routers from './routing/Router';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'

function App() {
  return (
   <Routers />
  );
}

export default App;
